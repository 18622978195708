<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-toolbar-title>Reimbursement Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark class="success">
                        <v-toolbar-title>Request Info</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field readonly v-for="(field,index) in  recFields" :key="'fieldIndex'+index" outlined v-model="recDetail[field.fieldName]" :label="field.name"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark class="warning">
                        <v-toolbar-title>Item List</v-toolbar-title>
                    </v-toolbar>
                    <v-card-title>
                    <v-text-field
                        v-model="tblSearch"
                        append-icon="mdi-magnify"
                        label="Search data below"
                        hide-details
                        outlined
                    ></v-text-field>
                    </v-card-title>

                    <v-container class="pt-2 mt-3">
                        <v-data-table
                        v-model="selected"  
                        ref="vuetable"
                        item-key="serial_number"
                        :headers="tblHeaders"
                        :items="tblBody"
                        :search="tblSearch"
                        :loading = "loadingFlag"
                        loading-text="Loading... Please wait"    
                        ></v-data-table>
                       
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import MpRdEvoucherService from "@/services/MpRdEvoucherService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Reimbursement',disabled: false,href:'/',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            statusList:[],
            form:[],
            recDetail:[],
            recFields:[
                {name:"Req Ref#",fieldName:"req_ref_no"},
                {name:"Merchant Name",fieldName:"merchant_name"},
                {name:"Outlet Name.",fieldName:"outlet_name"},
                {name:"Req Date",fieldName:"created_date"},
                {name:"Req By",fieldName:"created_by"},
                {name:"Total Item",fieldName:"total_item"},
                {name:"Total Qty",fieldName:"total_qty"},
                {name:"Total Amount",fieldName:"total_amount"},
            ],
            tblHeaders:[
                { text:"Code",value: "ci_code"  },
                { text:"Name", value:"ci_name" },
                { text:"Value", value:"ci_value" },
                { text:"Serial Number", value:"serial_number" },
                { text:"EDC Id", value:"edc_login_id" },                
                { text:"Process Ref #", value:"process_reference_no" },
            ],
            tblSearch:'',
            tblBody:[],
            routeParam:[],
    }
  },
  async created() {
      this.routeParam['req_ref_no'] = this.$route.params.req_ref_no;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let request = {req_ref_no:this.routeParam['req_ref_no']}
        try{
            MpRdEvoucherService.view_reimbursement(request).then((res) => {
                //console.log(res.data.data);
                this.recDetail = res.data.data;
                this.tblBody = res.data.itemList;

            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Reimbursement',e.response);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }

    },
    dlgClose(){
        this.visible = false;        
    },
  }
}
</script>